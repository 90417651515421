<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="promotionName"
                            label="推广活动名称"
                            label-width="8em"
                        >
                            <el-input
                                v-model="formModel.promotionName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="promotionTime"
                            label="活动时间"
                            label-width="8em"
                        >
                            <el-date-picker
                                :picker-options="$utils.CommonConfig.pickerOptions"
                                size="small"
                                v-model="formModel.promotionTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="promotionType"
                            label="推广类型"
                            label-width="8em"
                        >
                            <dictionaries-picker
                                type="ospType"
                                v-model="formModel.promotionType"
                                :disabled="formModel.id"
                            />
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            label="推广内容"
                            label-width="8em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="20"
                                v-model="allImageFileList"
                                tips=""
                                @input="uploadImage"
                                :oss-action="$globalVar.appConfig.OSS_ACTION"
                            />
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="imageFileList"
                                style="width: 100%"
                                class="ma-t"
                            >
                                <el-table-column
                                    prop="image"
                                    label="图片"
                                    width="150"
                                >
                                    <template slot-scope="scope">
                                        <ImageList
                                            :data="scope.row.address"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="link"
                                    label="链接"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.link"
                                            auto-complete="off"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="90"
                                >
                                    <template slot-scope="scope">
                                        <!--<el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteItem(scope.row)"
                                        >
                                            删除
                                        </el-button>-->
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onUpItem(scope.row, scope.$index)"
                                            v-if="scope.$index !== 0"
                                        >
                                            上移
                                        </el-button>
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDownItem(scope.row, scope.$index)"
                                            v-if="(imageFileList.length - 1) !== scope.$index"
                                        >
                                            下移
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="giveScope"
                            label="推广对象"
                            label-width="8em"
                        >
                            <el-radio
                                v-model="formModel.giveScope"
                                label="1"
                                :disabled="formModel.id"
                            >
                                所有会员
                            </el-radio>
                            <el-radio
                                v-model="formModel.giveScope"
                                label="2"
                                :disabled="formModel.id"
                            >
                                指定会员
                            </el-radio>
                            <el-radio
                                v-model="formModel.giveScope"
                                label="3"
                                :disabled="formModel.id"
                            >
                                指定会员等级
                            </el-radio>
                        </el-form-item>
                        <el-form-item
                            label="会员列表"
                            label-width="8em"
                            v-if="formModel.giveScope == 2"
                        >
                            <div
                                class="ma-b"
                            >
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddSelect"
                                        :disabled="formModel.id"
                                    >
                                        新增会员
                                    </el-button>
                                </el-button-group>
                            </div>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="selectDataList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="nickName"
                                    label="用户昵称"
                                    min-width="100"
                                />
                                <el-table-column
                                    label="头像"
                                    width="120"
                                >
                                    <template slot-scope="scope">
                                        <ImageList
                                            :data="scope.row.avatar"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="mobile"
                                    label="电话"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="registerTime"
                                    label="注册时间"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="buyerStatusName"
                                    label=" 买家状态"
                                    min-width="100"
                                />
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="45"
                                    v-if="!isEdit"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteSelect(scope.row)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item
                            prop="buyerLevelId"
                            label="会员等级"
                            label-width="8em"
                            v-if="formModel.giveScope == 3"
                        >
                            <el-select
                                v-model="formModel.buyerLevelId"
                                placeholder="请选择"
                                :disabled="formModel.id"
                            >
                                <el-option
                                    v-for="item in buyerLevelList"
                                    :key="item.id"
                                    :label="item.levelName"
                                    :value="item.id + ''"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="8em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="isAvailable"
                            label="是否可用"
                            label-width="8em"
                        >
                            <el-switch
                                v-model="formModel.isAvailable"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm"
                            >
                                保 存
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>
        </el-row>
        <!-- 选择会员 -->
        <el-dialog
            title="新增会员"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="nickName"
                        label="用户昵称"
                        min-width="100"
                    />
                    <el-table-column
                        label="头像"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.avatar"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="电话"
                        min-width="100"
                    />
                    <el-table-column
                        prop="registerTime"
                        label="注册时间"
                        min-width="100"
                    />
                    <el-table-column
                        prop="buyerStatusName"
                        label="买家状态"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { GlobalVar } from '@vmf/shared';

export default {
    name: 'OpenScreenPromotionProvideEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                promotionName: '',
                promotionTime: [],
                promotionType: '3',
                giveScope: '1',
                buyerLevelId: '',
                remarks: '',
                isAvailable: '',
            },
            // 表单校验规则
            formRules: {
                promotionName: {
                    required: true,
                    message: '请输入推广活动名称',
                    trigger: 'blur',
                },
                promotionTime: {
                    type: 'array',
                    required: true,
                    message: '请输入推广时间',
                },
                promotionType: {
                    required: true,
                    message: '请选择推广类型',
                },
                giveScope: {
                    required: true,
                    message: '请选择推广对象',
                },
                buyerLevelId: {
                    required: true,
                    message: '请选择会员等级',
                },
            },
            // 会员等级列表
            buyerLevelList: [],
            // 新增/编辑
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    // 用户昵称
                    nickName: '',
                    // 电话
                    mobile: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            selectDataList: [],
            // 表格当前选中行
            currentTableSelect: [],
            imageFileList: [],
            allImageFileList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const buyerIdList = [];
                this.selectDataList.forEach(item => {
                    buyerIdList.push(item.id);
                });
                if (+this.formModel.giveScope === 2 && +buyerIdList.length === 0) {
                    this.$message({
                        message: '请至少选择一个会员',
                        type: 'error',
                    });
                    return;
                }
                this.$api.Pm.OpenScreenPromotion.save({
                    ...this.formModel,
                    buyerIds: buyerIdList.join(','),
                    promotionContent: JSON.stringify(this.imageFileList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initShop() {
            this.$api.Pm.OpenScreenPromotion.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data.openScreenPromotion;
                this.$utils.formModelMerge(this.formModel, res);
                this.formModel.promotionTime = [res.promotionBeginTime, res.promotionEndTime];
                this.formModel.giveScope = `${this.formModel.giveScope}`;
                this.selectDataList = json.data.data.buyerList || [];
                this.imageFileList = JSON.parse(res.promotionContent) || [];
                this.imageFileList.forEach(item => {
                    item.address = GlobalVar.fileBasePath + item.name;
                    this.allImageFileList.push({
                        name: item.name,
                        address: item.address,
                    });
                });
                // eslint-disable-next-line eqeqeq
                if (this.formModel.giveScope == 3) {
                    // 会员等级
                    this.formModel.buyerLevelId = `${JSON.parse(res.buyerLevelInfo).buyerLevelId}`;
                }
            });
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectDataList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Buyer.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            this.selectDataList = [...this.selectDialog.currentTableSelect];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.selectDataList.splice(this.selectDataList.indexOf(row), 1);
        },
        onUpItem(row, index) {
            if (index !== 0) {
                [this.imageFileList[index]] = this.imageFileList.splice(index - 1, 1, this.imageFileList[index]);
            } else {
                this.imageFileList.push(this.imageFileList.shift());
            }
        },
        onDownItem(row, index) {
            if (index !== this.imageFileList.length - 1) {
                [this.imageFileList[index]] = this.imageFileList.splice(index + 1, 1, this.imageFileList[index]);
            } else {
                this.imageFileList.unshift(this.imageFileList.splice(index, 1)[0]);
            }
        },
        uploadImage() {
            this.allImageFileList.forEach(item => {
                let isHave = false;
                this.imageFileList.forEach(oldItem => {
                    if (oldItem.name === item.name) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    this.imageFileList.push({
                        name: item.name,
                        address: item.address,
                        desc: '',
                    });
                }
            });
            this.imageFileList.forEach(oldItem => {
                let isHave = false;
                this.allImageFileList.forEach(item => {
                    if (oldItem.name === item.name) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    this.onDeleteItem(oldItem);
                }
            });
        },
        onAddItem() {
            this.imageFileList.push({});
        },
        onDeleteItem(row) {
            this.imageFileList.splice(this.imageFileList.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initShop();
        }
        this.initBuyerLevelList();
    },
};
</script>

<style lang="scss">
</style>
