<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="couponShareType"
                            label="活动类型"
                            label-width="13em"
                        >
                            <dictionaries-picker
                                type="couponShareType"
                                v-model="formModel.couponShareType"
                                :disabled="!!formModel.id"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponShareName"
                            label="活动名称"
                            label-width="13em"
                        >
                            <el-input
                                v-model="formModel.couponShareName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="shareImage"
                            label="分享图片"
                            label-width="13em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.shareImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="adImage"
                            label="广告图"
                            label-width="13em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="10"
                                v-model="formModel.adImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="activityTime"
                            label="活动时间"
                            label-width="13em"
                        >
                            <el-date-picker
                                :picker-options="$utils.CommonConfig.pickerOptions"
                                size="small"
                                v-model="formModel.activityTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponShareDesc"
                            label="活动说明"
                            label-width="13em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.couponShareDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="totalAllowNum"
                            label="活动允许发起总量"
                            label-width="13em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.totalAllowNum"
                            />
                            为0不限制
                        </el-form-item>
                        <el-form-item
                            prop="buyerLimitTotalNum"
                            label="会员领取总量"
                            label-width="13em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.buyerLimitTotalNum"
                            />
                            为0不限制
                        </el-form-item>
                        <el-form-item
                            prop="buyerLimitDayNum"
                            label="会员每日领取总量"
                            label-width="13em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.buyerLimitDayNum"
                            />
                            为0不限制
                        </el-form-item>
                        <el-form-item
                            prop="isCancelClose"
                            label="订单取消是否结束分享"
                            label-width="13em"
                        >
                            <el-switch
                                v-model="formModel.isCancelClose"
                            />
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="24">
                        <el-form-item
                            label="指定顺序优惠券"
                            label-width="13em"
                            v-if="formModel.couponShareType == 1"
                        >
                            <div
                                class="ma-b"
                            >
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddSelectCoupon(1)"
                                    >
                                        选择优惠券
                                    </el-button>
                                </el-button-group>
                            </div>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="selectCouponDataList1"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="templetName"
                                    label="模板名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="templetTypeName"
                                    label="模板类型"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="couponAmount"
                                    label="优惠券金额"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="couponUseAmount"
                                    label="优惠券满减限制"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="remarks"
                                    label="模板备注"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="minReceiveIndex"
                                    label="指定领取顺序"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        <el-input-number
                                            controls-position="right"
                                            :min="1"
                                            :precision="0"
                                            v-model.number="scope.row.minReceiveIndex"
                                        />
                                    </template>
                                </el-table-column>
                                <!--                                <el-table-column
                                                                    prop="receiveNum"
                                                                    label="优惠券数量"
                                                                    min-width="100"
                                                                >
                                                                    <template slot-scope="scope">
                                                                        <el-input-number
                                                                            controls-position="right"
                                                                            :min="1"
                                                                            :precision="0"
                                                                            v-model.number="scope.row.receiveNum"
                                                                        />
                                                                    </template>
                                                                </el-table-column>-->
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="45"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteSelectCoupon(selectCouponDataList1, scope.row)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item
                            label="随机优惠券"
                            label-width="13em"
                        >
                            <div
                                class="ma-b"
                            >
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddSelectCoupon(2)"
                                    >
                                        选择优惠券
                                    </el-button>
                                </el-button-group>
                            </div>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="selectCouponDataList2"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="templetName"
                                    label="模板名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="templetTypeName"
                                    label="模板类型"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="couponAmount"
                                    label="优惠券金额"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="couponUseAmount"
                                    label="优惠券满减限制"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="remarks"
                                    label="模板备注"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="receiveNum"
                                    label="优惠券数量"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        <el-input-number
                                            controls-position="right"
                                            :min="1"
                                            :precision="0"
                                            v-model.number="scope.row.receiveNum"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="45"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteSelectCoupon(selectCouponDataList2, scope.row)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="13em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="isAvailable"
                            label="是否可用"
                            label-width="13em"
                        >
                            <el-switch
                                v-model="formModel.isAvailable"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="13em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm"
                            >
                                保 存
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>
        </el-row>

        <el-dialog
            title="选择优惠券"
            center
            width="1200px"
            :visible.sync="selectCouponDialog.isVisible"
            @closed="onSelectCouponCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectCouponDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectCouponDialog.queryFormModel"
            >
                <el-form-item
                    prop="templetName"
                    label="模板名称"
                >
                    <el-input
                        v-model="selectCouponDialog.queryFormModel.templetName"
                        placeholder="请输入模板名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectCouponDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectCouponDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectCouponDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="couponTempletId"
                    ref="selectCouponDialogTable"
                    @selection-change="onSelectCouponDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="templetName"
                        label="模板名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="templetTypeName"
                        label="模板类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponAmount"
                        label="优惠券金额"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponUseAmount"
                        label="优惠券满减限制"
                        min-width="100"
                    />
                    <el-table-column
                        prop="remarks"
                        label="模板备注"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                        v-if="selectCouponDialog.type === 1"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectCouponConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectCouponDialog.pagination"
                        @input="onSelectCouponDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectCouponConfirm"
                    v-if="selectCouponDialog.type === 2"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCouponCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'CouponGiveReceiveEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                couponShareType: 1,
                couponShareName: '',
                activityTime: '',
                beginTime: '',
                endTime: '',
                couponShareDesc: '',
                totalAllowNum: 0,
                buyerLimitTotalNum: 0,
                buyerLimitDayNum: 0,
                isCancelClose: true,
                remarks: '',
                isAvailable: '',
                shareImage: [],
                adImage: [],
            },
            // 表单校验规则
            formRules: {
                couponShareName: {
                    required: true,
                    message: '请输入活动名称',
                    trigger: 'blur',
                },
                couponShareType: {
                    required: true,
                    message: '请选择活动类型',
                },
                activityTime: {
                    type: 'array',
                    required: true,
                    message: '请选择活动时间',
                },
                totalAllowNum: {
                    required: true,
                    message: '请输入活动允许发起总量',
                    trigger: 'blur',
                },
                buyerLimitTotalNum: {
                    required: true,
                    message: '请输入会员领取总量',
                    trigger: 'blur',
                },
                buyerLimitDayNum: {
                    required: true,
                    message: '请输入会员每日领取总量',
                    trigger: 'blur',
                },
                isCancelClose: {
                    required: true,
                    message: '请选择订单取消是否结束分享',
                },
            },
            selectCouponDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    templetName: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
                type: 1,
            },
            // 商品表格数据
            selectCouponDataList1: [],
            selectCouponDataList2: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                if (+this.formModel.couponShareType === 1 && this.selectCouponDataList1.length === 0) {
                    this.$message({
                        message: '请选择指定顺序优惠券',
                        type: 'error',
                    });
                    return;
                }
                if (this.selectCouponDataList2.length === 0) {
                    this.$message({
                        message: '请选择随机优惠券',
                        type: 'error',
                    });
                    return;
                }
                this.selectCouponDataList2.forEach(item => {
                    if (!item.maxReceiveIndex) {
                        item.maxReceiveIndex = item.minReceiveIndex;
                    }
                });
                const list = [];
                list.push(...this.selectCouponDataList1);
                list.push(...this.selectCouponDataList2);

                [this.formModel.beginTime, this.formModel.endTime] = this.formModel.activityTime;
                this.$api.Pm.CouponShare.save({
                    ...this.formModel,
                    detailJson: JSON.stringify(list),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initShop() {
            this.$api.Pm.CouponShare.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res.couponShare);
                this.formModel.activityTime = [this.formModel.beginTime, this.formModel.endTime];
                this.selectCouponDataList1 = res.selectCouponDataList1 || [];
                this.selectCouponDataList2 = res.selectCouponDataList2 || [];
            });
        },
        onAddSelectCoupon(type) {
            this.selectCouponDialog.type = type;
            this.selectCouponDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectCouponDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectCouponDialogTable) {
                    this.$refs.selectCouponDialogTable.clearSelection();
                }
                if (this.selectCouponDialog.type === 1) {
                    this.selectCouponDataList1.forEach(item => {
                        this.$refs.selectCouponDialogTable.toggleRowSelection(item, true);
                    });
                } else if (this.selectCouponDialog.type === 2) {
                    this.selectCouponDataList2.forEach(item => {
                        this.$refs.selectCouponDialogTable.toggleRowSelection(item, true);
                    });
                }
            });
        },
        onSelectCouponDialogQuery(pagination) {
            return this.$api.Pm.CouponTemplet.data({
                ...this.selectCouponDialog.queryFormModel,
                ...pagination,
                isAvailable: 1,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    item.couponTempletId = item.id;
                });
                this.selectCouponDialog.tableData = res.data;
                this.selectCouponDialog.pagination = res.pagination;
            });
        },
        onSelectCouponDialogSelectionChange(val) {
            this.selectCouponDialog.currentTableSelect = val;
        },
        onResetSelectCouponDialog() {
            this.$refs.selectCouponDialogQueryForm.resetFields();
        },
        onSelectCouponConfirm(row) {
            // 插入新数据
            const list = [];
            if (this.selectCouponDialog.type === 1) {
                const item = { ...row };
                item.id = null;
                item.receiveType = 1;
                item.receiveNum = 1;
                this.selectCouponDataList1 = [item];
            } else if (this.selectCouponDialog.type === 2) {
                this.selectCouponDialog.currentTableSelect.forEach(item => {
                    let isHave = false;
                    this.selectCouponDataList2.forEach(oldItem => {
                        if (item.couponTempletId === oldItem.couponTempletId) {
                            list.push({ ...oldItem });
                            isHave = true;
                        }
                    });
                    if (!isHave) {
                        // 添加新数据
                        item.id = null;
                        item.receiveType = 2;
                        item.receiveNum = 1;
                        list.push({ ...item });
                    }
                });
                this.selectCouponDataList2 = [...list];
            }
            this.selectCouponDialog.isVisible = false;
        },
        onSelectCouponCancel() {
            this.selectCouponDialog.isVisible = false;
        },
        onDeleteSelectCoupon(list, row) {
            list.splice(list.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initShop();
        }
    },
};
</script>

<style lang="scss">
</style>
