<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="couponType"
                    :label="$t('pageKey787', '优惠券类型')"
                >
                    <dictionaries-picker
                        type="couponType"
                        v-model="queryFormModel.couponType"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="couponCode"
                    :label="$t('pageKey829', '优惠码')"
                >
                    <el-input
                        v-model="queryFormModel.couponCode"
                        :placeholder="$t('pageKey830', '请输入优惠码')"
                    />
                </el-form-item>
                <el-form-item
                    prop="drawLimitType"
                    :label="$t('pageKey831', '用户领券限制类型')"
                >
                    <dictionaries-picker
                        type="drawLimitType"
                        v-model="queryFormModel.drawLimitType"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    :label="$t('pageKey789', '发放备注')"
                >
                    <el-input
                        v-model="queryFormModel.remarks"
                        :placeholder="$t('pageKey790', '请输入发放备注')"
                    />
                </el-form-item>
                <el-form-item
                    prop="insertTime"
                    :label="$t('pageKey796', '发放时间')"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.insertTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :range-separator="$t('pageKey475', '至')"
                        :start-placeholder="$t('pageKey474', '开始日期')"
                        :end-placeholder="$t('pageKey476', '结束日期')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/om/coupon/couponGiveReceive/edit')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="couponTypeName"
                    :label="$t('pageKey787', '优惠券类型')"
                    width="85"
                />
                <el-table-column
                    prop="drawLimitTypeName"
                    :label="$t('pageKey831', '用户领券限制类型')"
                    min-width="120"
                />
                <el-table-column
                    prop="couponCode"
                    :label="$t('pageKey829', '优惠码')"
                    min-width="100"
                />
                <el-table-column
                    prop="couponDesc"
                    :label="$t('pageKey791', '优惠券名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="couponUseScopeTypeName"
                    :label="$t('pageKey792', '优惠券使用范围')"
                    min-width="110"
                />
                <el-table-column
                    prop="couponAmountTypeName"
                    :label="$t('pageKey793', '优惠券金额类型')"
                    min-width="110"
                />
                <el-table-column
                    prop="couponAmount"
                    :label="$t('pageKey794', '优惠券金额或比例')"
                    min-width="120"
                />
                <el-table-column
                    :label="$t('pageKey832', '领取开始时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.drawBeginTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey833', '领取截止时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.drawEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    :label="$t('pageKey789', '发放备注')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey796', '发放时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/om/coupon/couponGiveReceive/edit/' + scope.row.id)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CouponGiveProvide',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                couponGiveMode: 2, // 优惠券发放模式
                insertTime: '', // 发放时间
                remarks: '', // 发放备注
                couponCode: '',
                couponType: '',
                drawLimitType: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.CouponGive.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pm.CouponGive.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
    },
};
</script>

<style lang="scss">
</style>
