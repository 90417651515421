<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="couponActivityId"
                            :label="$t('pageKey797', '优惠券活动')"
                            label-width="15em"
                        >
                            <RemoteSelect
                                clearable
                                remote="/pm/couponActivity/select"
                                :placeholder="$t('pageKey238', '请输入')"
                                :props="{id:'id',name:'activityName'}"
                                v-model="formModel.couponActivityId"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponType"
                            :label="$t('pageKey787', '优惠券类型')"
                            label-width="15em"
                        >
                            <dictionaries-picker
                                type="couponType"
                                v-model="formModel.couponType"
                                :placeholder="$t('pageKey101', '请选择')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponCode"
                            :label="$t('pageKey829', '优惠码')"
                            label-width="15em"
                        >
                            <el-input
                                v-model="formModel.couponCode"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponDesc"
                            :label="$t('pageKey791', '优惠券名称')"
                            label-width="15em"
                        >
                            <el-input
                                v-model="formModel.couponDesc"
                                auto-complete="off"
                                :disabled="isEdit"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponShortDesc"
                            :label="$t('pageKey834', '优惠券简述')"
                            label-width="15em"
                        >
                            <el-input
                                v-model="formModel.couponShortDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponImage"
                            :label="$t('pageKey798', '优惠券图标')"
                            label-width="15em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.couponImage"
                            />
                        </el-form-item>
                        <!--<el-form-item
    prop="couponTempletId"
    label="模板名称"
    label-width="15em"
>
    <el-select
        v-model="couponTemplet"
        value-key="id"
        placeholder="请选择"
        @change="someItemChange(couponTemplet)"
        :disabled="isEdit"
    >
        <el-option
            v-for="item in couponTempletList"
            :key="item.id"
            :label="item.templetName"
            :value="item"/>
    </el-select>
</el-form-item>-->
                        <el-form-item
                            prop="drawBeginTime"
                            :label="$t('pageKey832', '领取开始时间')"
                            label-width="15em"
                        >
                            <el-date-picker
                                v-model="formModel.drawBeginTime"
                                type="datetime"
                                :placeholder="$t('pageKey835', '选择领取开始时间')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="drawEndTime"
                            :label="$t('pageKey833', '领取截止时间')"
                            label-width="15em"
                        >
                            <el-date-picker
                                v-model="formModel.drawEndTime"
                                type="datetime"
                                :placeholder="$t('pageKey836', '选择领取截止时间')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponAmountType"
                            :label="$t('pageKey793', '优惠券金额类型')"
                            label-width="15em"
                        >
                            <dictionaries-picker
                                type="couponAmountType"
                                v-model="formModel.couponAmountType"
                                :disabled="isEdit"
                                :clearable="false"
                                :placeholder="$t('pageKey101', '请选择')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponAmount"
                            :label="+formModel.couponAmountType === 1?$t('pageKey658', '优惠券金额'):$t('pageKey799', '优惠券折扣比例')"
                            label-width="15em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="formModel.couponAmount"
                                :disabled="isEdit"
                                v-if="+formModel.couponAmountType === 1"
                            />
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :max="10"
                                :precision="2"
                                v-model.number="formModel.couponAmount"
                                :disabled="isEdit"
                                v-if="+formModel.couponAmountType === 2"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="discountMaxAmount"
                            :label="$t('pageKey800', '折扣券最大金额')"
                            label-width="15em"
                            v-if="+formModel.couponAmountType === 2"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="formModel.discountMaxAmount"
                                :disabled="isEdit"
                            />
                            {{ $t('pageKey801', '为0不限制') }}
                        </el-form-item>
                        <el-form-item
                            prop="couponUseAmount"
                            :label="$t('pageKey802', '优惠券满减金额限制')"
                            label-width="15em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="formModel.couponUseAmount"
                                :disabled="isEdit"
                            />
                            {{ $t('pageKey801', '为0不限制') }}
                        </el-form-item>
                        <el-form-item
                            prop="couponExpireMode"
                            :label="$t('pageKey803', '优惠券到期模式')"
                            label-width="15em"
                        >
                            <dictionaries-picker
                                type="couponExpireMode"
                                v-model="formModel.couponExpireMode"
                                :disabled="isEdit"
                                :clearable="false"
                                :placeholder="$t('pageKey101', '请选择')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponPeriod"
                            :label="$t('pageKey804', '优惠券有效期(天)')"
                            label-width="15em"
                            v-if="formModel.couponExpireMode == 1"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.couponPeriod"
                                :disabled="isEdit"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponExpireTime"
                            :label="$t('pageKey805', '优惠券有效期至')"
                            label-width="15em"
                            v-if="formModel.couponExpireMode == 2"
                        >
                            <el-date-picker
                                v-model="formModel.couponExpireTime"
                                type="datetime"
                                :placeholder="$t('pageKey806', '选择优惠券有效期')"
                                :disabled="isEdit"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="couponGiveQuantity"
                            :label="$t('pageKey837', '发放总量')"
                            label-width="15em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.couponGiveQuantity"
                            />
                            {{ $t('pageKey801', '为0不限制') }}
                        </el-form-item>
                        <el-form-item
                            prop="drawLimitType"
                            :label="$t('pageKey831', '用户领券限制类型')"
                            label-width="15em"
                        >
                            <dictionaries-picker
                                type="drawLimitType"
                                v-model="formModel.drawLimitType"
                                :disabled="isEdit"
                                :clearable="false"
                                :placeholder="$t('pageKey101', '请选择')"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="drawQuantityLimit"
                            :label="$t('pageKey838', '单用户领取数量限制')"
                            label-width="15em"
                            v-if="formModel.drawLimitType == 1"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="0"
                                v-model.number="formModel.drawQuantityLimit"
                                :disabled="isEdit"/>
                            {{ $t('pageKey801', '为0不限制') }}
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="couponUseScopeType"
                            :label="$t('pageKey792', '优惠券使用范围')"
                            label-width="15em"
                        >
                            <dictionaries-picker
                                type="couponUseScopeType"
                                v-model="formModel.couponUseScopeType"
                                :clearable="false"
                                :placeholder="$t('pageKey101', '请选择')"
                            />
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey500', '商品列表')"
                            label-width="15em"
                            v-if="formModel.couponUseScopeType == 2 || formModel.couponUseScopeType == 4"
                        >
                            <div
                                class="ma-b"
                            >
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddSelectGoods"
                                    >
                                        {{ $t('pageKey328', '选择商品') }}
                                    </el-button>
                                </el-button-group>
                            </div>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="selectGoodsDataList"
                                style="width: 100%"
                            >
                                <!-- 商品规格 -->
                                <el-table-column type="expand">
                                    <template slot-scope="goods">
                                        <el-table
                                            stripe
                                            border
                                            size="small"
                                            :data="goods.row.skuList"
                                            style="width: 100%"
                                        >
                                            <el-table-column
                                                prop="specDesc"
                                                :label="$t('pageKey254', '商品规格')"
                                                min-width="100"
                                            />
                                            <el-table-column
                                                prop="price"
                                                :label="$t('pageKey243', '价格')"
                                                min-width="50"
                                            />
                                            <el-table-column
                                                prop="stock"
                                                :label="$t('pageKey218', '库存')"
                                                min-width="50"
                                            />
                                        </el-table>
                                    </template>
                                </el-table-column>
                                <!--<el-table-column
    label="商品图片"
    width="120"
>
    <template slot-scope="scope">
        <ImageList
            :data="scope.row.mainImageUrl"
        />
    </template>
</el-table-column>-->
                                <el-table-column
                                    prop="goodsName"
                                    :label="$t('pageKey204', '商品名称')"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="goodsSn"
                                    :label="$t('pageKey202', '商品编号')"
                                    min-width="100"
                                />
                                <el-table-column
                                    fixed="right"
                                    :label="$t('pageKey13', '操作')"
                                    width="45"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteSelectGoods(scope.row)"
                                        >
                                            {{ $t('pageKey17', '删除') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- 分页 -->
                            <div
                                class="ma-t ta-r">
                                <pagination
                                    v-model="pagination"
                                    @input="onSelectDialogQuery"
                                />
                            </div>
                        </el-form-item>
                        <el-form-item
                            prop="couponShipMethod"
                            :label="$t('pageKey898', '订单送货方式')"
                            label-width="15em"
                        >
                            <checkbox-group-andOr
                                v-model="formModel.couponShipMethod"
                                :data="shipMethodList"
                                :disabled="isEdit"
                            />
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="16">
                        <el-form-item
                            prop="couponUseRemarks"
                            :label="$t('pageKey815', '优惠券使用说明')"
                            label-width="15em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.couponUseRemarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="remarks"
                            :label="$t('pageKey789', '发放备注')"
                            label-width="15em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey839', '已发放人数')"
                            label-width="15em"
                            v-if="isEdit"
                        >
                            {{ formModel.couponDrawQuantity }}
                        </el-form-item>
                        <el-form-item
                            label-width="15em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm"
                            >
                                {{ $t('pageKey73', '保 存') }}
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                {{ $t('pageKey126', '返 回') }}
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>
        </el-row>

        <!-- 选择商品 -->
        <el-dialog
            :title="$t('pageKey328', '选择商品')"
            center
            width="1200px"
            :visible.sync="selectGoodsDialog.isVisible"
            @closed="onSelectGoodsCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectGoodsDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectGoodsDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品编号')"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    :label="$t('pageKey206', '商品类目')"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="selectGoodsDialog.queryFormModel.goodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    :label="$t('pageKey327', '商品分组')"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'tagName'}"
                        v-model="selectGoodsDialog.queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectGoodsDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectGoodsDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectGoodsDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectGoodsDialogTable"
                    @selection-change="onSelectGoodsDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        :label="$t('pageKey215', '商品图片')"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectGoodsDialog.pagination"
                        @input="onSelectGoodsDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectGoodsConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectGoodsCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'CouponGiveReceiveEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                couponCode: '',
                // 优惠券名称
                couponDesc: '',
                couponShortDesc: '',
                // 模板名称ID
                couponTempletId: '',
                // 领取开始时间
                drawBeginTime: '',
                // 领取截止时间
                drawEndTime: '',
                // 优惠券金额
                couponAmount: '',
                // 优惠券满减金额限制
                couponUseAmount: '',
                // 优惠券到期模式
                couponExpireMode: '',
                // 优惠券有效期(天)
                couponPeriod: '',
                // 优惠券到期时间
                couponExpireTime: '',
                // 发放备注
                remarks: '',

                couponAmountType: 1,
                discountMaxAmount: 0,
                couponUseScopeType: 1,
                couponUseScope: '',
                couponGiveQuantity: 0,
                drawQuantityLimit: 1,
                couponDrawQuantity: '',
                couponActivityId: '',
                couponType: '1',
                couponImage: [],
                couponUseRemarks: '',
                drawLimitType: '1',
                couponShipMethod: '',
            },
            // 表单校验规则
            formRules: {
                couponCode: {
                    required: true,
                    message: this.$t('pageKey830', '请输入优惠码'),
                    trigger: 'blur',
                },
                couponDesc: {
                    required: true,
                    message: this.$t('pageKey816', '请输入优惠券名称'),
                    trigger: 'blur',
                },
                couponAmount: {
                    required: true,
                    message: this.$t('pageKey817', '请输入优惠券金额'),
                    trigger: 'blur',
                },
                couponUseAmount: {
                    required: true,
                    message: this.$t('pageKey818', '请输入优惠券满减金额限制'),
                    trigger: 'blur',
                },
                couponExpireMode: {
                    required: true,
                    message: this.$t('pageKey819', '请选择优惠券到期模式'),
                },
                couponAmountType: {
                    required: true,
                    message: this.$t('pageKey820', '请选择优惠券金额类型'),
                },
                couponUseScopeType: {
                    required: true,
                    message: this.$t('pageKey821', '请选择优惠券使用范围'),
                },
                drawLimitType: {
                    required: true,
                    message: this.$t('pageKey840', '请选择用户领券限制类型'),
                },
                couponPeriod: {
                    required: true,
                    message: this.$t('pageKey822', '请输入优惠券有效期(天)'),
                    trigger: 'blur',
                },
                discountMaxAmount: {
                    required: true,
                    message: this.$t('pageKey823', '请输入折扣券最大金额'),
                    trigger: 'blur',
                },
                couponExpireTime: {
                    required: true,
                    message: this.$t('pageKey824', '请选择优惠券到期时间'),
                    trigger: 'blur',
                },
                remarks: {
                    required: true,
                    message: this.$t('pageKey790', '请输入发放备注'),
                    trigger: 'blur',
                },
                drawBeginTime: {
                    required: true,
                    message: this.$t('pageKey841', '请输入开始时间'),
                    trigger: 'blur',
                },
                drawEndTime: {
                    required: true,
                    message: this.$t('pageKey842', '请输入结束时间'),
                    trigger: 'blur',
                },
                couponGiveQuantity: {
                    required: true,
                    message: this.$t('pageKey843', '请输入发放总量'),
                    trigger: 'blur',
                },
                drawQuantityLimit: {
                    required: true,
                    message: this.$t('pageKey844', '请输入单用户领取限制'),
                    trigger: 'blur',
                },
                couponType: {
                    required: true,
                    message: this.$t('pageKey826', '请选择优惠券类型'),
                },
            },
            // 优惠券模板列表
            couponTempletList: [],
            // 优惠券到期模式列表
            couponExpireModeList: [],
            // 模板对象
            couponTemplet: {},
            // 是否编辑
            isEdit: false,
            // 已发放人数
            buyerCount: 0,
            // 商品弹窗
            selectGoodsDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    shopTagIdList: [],
                    goodsCatId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
            // 商品表格数据
            selectGoodsDataList: [],
            // 分页
            pagination: {},
            shipMethodList: [],
            selectApi: this.$api.Gd.GoodsCat,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                let couponUseScope = '';
                if (+this.formModel.couponUseScopeType === 2 || +this.formModel.couponUseScopeType === 4) {
                    const goodsIdList = [];
                    this.selectGoodsDataList.forEach(item => {
                        goodsIdList.push(item.goodsId);
                    });
                    if (goodsIdList.length === 0) {
                        this.$message({
                            message: this.$t('pageKey828', '请至少选择一个商品'),
                            type: 'error',
                        });
                        return;
                    }
                    couponUseScope = goodsIdList.join(',');
                }
                this.$api.Pm.CouponGive.save({
                    ...this.formModel,
                    couponGiveMode: 2,
                    couponUseScope,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initShop() {
            this.$api.Pm.CouponGive.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data.couponGive;
                if (!res.couponActivityId) {
                    res.couponActivityId = '';
                }
                this.$utils.formModelMerge(this.formModel, res);
                this.couponTemplet = json.data.data.couponTempletName;
                if (this.formModel.couponUseScopeType === 2 || this.formModel.couponUseScopeType === 4) {
                    this.onSelectDialogQuery();
                }
            });
        },
        initCouponTempletList() {
            this.$api.Pm.CouponTemplet.data({ isAvailable: 1, couponGiveMode: 2 }).then(json => {
                const res = json.data.data;
                this.couponTempletList = res;
            });
        },
        someItemChange(value) {
            this.formModel.couponTempletId = `${value.id}`;
            this.formModel.couponTempletType = value.templetType;
            this.formModel.couponAmount = value.couponAmount;
            this.formModel.couponUseAmount = value.couponUseAmount;
            this.formModel.couponPeriod = value.couponPeriod;
            this.formModel.couponShipMethod = value.couponShipMethod;
            this.formModel.couponGiveMode = `${value.couponGiveMode}`;
        },
        /* -- 事件 -- */
        onAddSelectGoods() {
            this.selectGoodsDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectGoodsDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectGoodsDialogTable) {
                    this.$refs.selectGoodsDialogTable.clearSelection();
                }
                this.selectGoodsDataList.forEach(item => {
                    this.$refs.selectGoodsDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectGoodsDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...this.selectGoodsDialog.queryFormModel,
                ...pagination,
                goodsStatusTypeNull: 1,
                goodsType: 1,
            }).then(json => {
                const res = json.data;
                this.selectGoodsDialog.tableData = res.data;
                this.selectGoodsDialog.pagination = res.pagination;
            });
        },
        onSelectGoodsDialogSelectionChange(val) {
            this.selectGoodsDialog.currentTableSelect = val;
        },
        onResetSelectGoodsDialog() {
            this.$refs.selectGoodsDialogQueryForm.resetFields();
        },
        onSelectGoodsConfirm() {
            // 插入新数据
            const list = [];
            this.selectGoodsDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.selectGoodsDataList.forEach(oldItem => {
                    if (item.goodsId === oldItem.goodsId) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    // 添加新数据
                    item.id = null;
                    list.push({ ...item });
                }
            });
            this.selectGoodsDataList = [...list];
            this.selectGoodsDialog.isVisible = false;
        },
        onSelectGoodsCancel() {
            this.selectGoodsDialog.isVisible = false;
        },
        onDeleteSelectGoods(row) {
            this.selectGoodsDataList.splice(this.selectGoodsDataList.indexOf(row), 1);
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...pagination,
                couponGiveId: this.formModel.id,
                goodsStatusTypeNull: 1,
            }).then(json => {
                const res = json.data;
                this.selectGoodsDataList = res.data;
                this.pagination = res.pagination;
            });
        },
        initShipMethodList() {
            this.$api.Sp.Shop.getShopShipMethodList({}).then(json => {
                this.shipMethodList = json.data.data;
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initShop();
            this.isEdit = true;
        } else {
            this.formModel.couponExpireMode = 1;
        }
        this.initCouponTempletList();
        this.initShipMethodList();
    },
};
</script>

<style lang="scss">
</style>
