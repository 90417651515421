<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="couponGiveCaseName"
                    label="场景名称"
                    min-width="100"
                />
                <el-table-column
                    label="是否启用"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                            disabled
                            v-if="!scope.row.id"
                        />
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                            v-if="scope.row.id"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="couponGiveCaseName"
                    label="优惠券场景"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.couponGiveCaseName"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="couponGiveCase"
                    label="优惠券代码"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.couponGiveCase"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-col :span="24">
                    <el-form-item
                        label="优惠券列表"
                        label-width="8em"
                    >
                        <div
                            class="ma-b"
                        >
                            <el-button-group>
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddSelectCoupon(2)"
                                >
                                    选择优惠券
                                </el-button>
                            </el-button-group>
                        </div>
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="selectCouponDataList2"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="templetName"
                                label="模板名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="templetTypeName"
                                label="模板类型"
                                width="100"
                            />
                            <el-table-column
                                prop="couponAmount"
                                label="优惠券金额"
                                width="100"
                            />
                            <el-table-column
                                prop="couponUseAmount"
                                label="优惠券满减限制"
                                width="110"
                            />
                            <el-table-column
                                prop="remarks"
                                label="模板备注"
                                min-width="100"
                            />
                            <el-table-column
                                prop="couponNum"
                                label="优惠券数量"
                                width="150"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        controls-position="right"
                                        :min="1"
                                        :precision="0"
                                        v-model.number="scope.row.couponNum"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteSelectCoupon(selectCouponDataList2, scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="选择优惠券"
            center
            width="1200px"
            :visible.sync="selectCouponDialog.isVisible"
            @closed="onSelectCouponCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectCouponDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectCouponDialog.queryFormModel"
            >
                <el-form-item
                    prop="templetName"
                    label="模板名称"
                >
                    <el-input
                        v-model="selectCouponDialog.queryFormModel.templetName"
                        placeholder="请输入模板名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectCouponDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectCouponDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectCouponDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="couponTempletId"
                    ref="selectCouponDialogTable"
                    @selection-change="onSelectCouponDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="templetName"
                        label="模板名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="templetTypeName"
                        label="模板类型"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponAmount"
                        label="优惠券金额"
                        min-width="100"
                    />
                    <el-table-column
                        prop="couponUseAmount"
                        label="优惠券满减限制"
                        min-width="100"
                    />
                    <el-table-column
                        prop="remarks"
                        label="模板备注"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100"
                        v-if="selectCouponDialog.type === 1"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectCouponConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectCouponDialog.pagination"
                        @input="onSelectCouponDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectCouponConfirm"
                    v-if="selectCouponDialog.type === 2"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCouponCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CouponGiveConfig',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    couponGiveCaseName: '',
                    couponGiveCase: '',
                    couponConfig: '',
                    isAvailable: true,
                },
                // 表单校验规则
                formRules: {},
            },
            // 表格数据
            tableData: [],
            selectCouponDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    templetName: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
                type: 1,
            },
            // 商品表格数据
            selectCouponDataList2: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.CouponGiveConfig.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pm.CouponGiveConfig.getDetail({ couponGiveCase: row.couponGiveCase }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res.couponGiveConfig);
                this.selectCouponDataList2 = res.couponList || [];
            });
            this.addDialog.isVisible = true;
            this.addDialog.formModel.couponGiveCaseName = row.couponGiveCaseName;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const couponConfig = [];
                this.selectCouponDataList2.forEach(item => {
                    couponConfig.push({ couponTempletId: item.id, couponNum: item.couponNum });
                });
                this.$api.Pm.CouponGiveConfig.save({
                    ...this.addDialog.formModel,
                    couponConfig: JSON.stringify(couponConfig),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Pm.CouponGiveConfig.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        onAddSelectCoupon(type) {
            this.selectCouponDialog.type = type;
            this.selectCouponDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectCouponDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectCouponDialogTable) {
                    this.$refs.selectCouponDialogTable.clearSelection();
                }
                if (this.selectCouponDialog.type === 1) {
                    this.selectCouponDataList1.forEach(item => {
                        this.$refs.selectCouponDialogTable.toggleRowSelection(item, true);
                    });
                } else if (this.selectCouponDialog.type === 2) {
                    this.selectCouponDataList2.forEach(item => {
                        this.$refs.selectCouponDialogTable.toggleRowSelection(item, true);
                    });
                }
            });
        },
        onSelectCouponDialogQuery(pagination) {
            return this.$api.Pm.CouponTemplet.data({
                ...this.selectCouponDialog.queryFormModel,
                ...pagination,
                isAvailable: 1,
            }).then(json => {
                const res = json.data;
                this.selectCouponDialog.tableData = res.data;
                this.selectCouponDialog.pagination = res.pagination;
            });
        },
        onSelectCouponDialogSelectionChange(val) {
            this.selectCouponDialog.currentTableSelect = val;
        },
        onResetSelectCouponDialog() {
            this.$refs.selectCouponDialogQueryForm.resetFields();
        },
        onSelectCouponConfirm(row) {
            // 插入新数据
            const list = [];
            if (this.selectCouponDialog.type === 1) {
                const item = { ...row, couponNum: 1 };
                this.selectCouponDataList1 = [item];
            } else if (this.selectCouponDialog.type === 2) {
                this.selectCouponDialog.currentTableSelect.forEach(item => {
                    let isHave = false;
                    this.selectCouponDataList2.forEach(oldItem => {
                        if (item.couponTempletId === oldItem.couponTempletId) {
                            list.push({ ...oldItem });
                            isHave = true;
                        }
                    });
                    if (!isHave) {
                        // 添加新数据
                        list.push({ ...item, couponNum: 1 });
                    }
                });
                this.selectCouponDataList2 = [...list];
            }
            this.selectCouponDialog.isVisible = false;
        },
        onSelectCouponCancel() {
            this.selectCouponDialog.isVisible = false;
        },
        onDeleteSelectCoupon(list, row) {
            list.splice(list.indexOf(row), 1);
        },
    },
};
</script>

<style lang="scss">
</style>
